import animationData from "../Assets/loader.json";
import Lottie from "react-lottie";

export default function Loader ({ height, width }) {
    const loader = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return <Lottie options={loader} height={height} width={width} />
}