import React, { useState } from "react";
import { Navigate, Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../Components/AuthContext.js";
import {
  doCreateUserWithEmailAndPassword,
  doSignInWithGoogle,
} from "../firebase/auth";

const Register = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [college, setCollege] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [phone, setPhone] = useState("");
  const [resume, setResume] = useState("");
  const [x, setX] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [github, setGithub] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [additional, setAdditional] = useState("");
  const [headline, setHeadline] = useState("");
  const [degreeType, setDegreeType] = useState("");
  const [major, setMajor] = useState("");
  const [collegeStartYear, setCollegeStartYear] = useState("");
  const [collegeStartMonth, setCollegeStartMonth] = useState("");
  const [collegeEndYear, setCollegeEndYear] = useState("");
  const [collegeEndMonth, setCollegeEndMonth] = useState("");

  const { userLoggedIn, setCurrentUser, currentUser, setCardId, cardId } =
    useAuth();

  //get user info from api, fetched by email
  const { id } = useParams();
  if (id) {
    setCardId(id);
  }
  const defaultUserObject = async (email) => {
    if (!currentUser || !currentUser.email || id) {
      window.location.replace(`/profile/${id}`);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}get_profile_from_email`,
        { email: currentUser.email }
      );

      console.log("response:", response);

      if (response.status === 200) {
        const profile = response.data.profiles[0];
        setCardId(response.data.cardId);
        setLinkedIn(profile.linkedIn);
        setX(profile.x);
        setCollege(profile.college);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setPhone(response.data.phone);
        setPortfolio(profile.portfolio);
        setResume(profile.resume);
        setImageUrl(profile.imageUrl);
        setImageUrl(profile.imageUrl);
        setAdditional(profile.additional);
        setGithub(profile.github);
        setHeadline(profile.headline);
        setDegreeType(profile.degreeType);
        setMajor(profile.major);
        setCollegeStartYear(profile.collegeStartYear);
        setCollegeStartMonth(profile.collegeStartMonth);
        setCollegeEndYear(profile.collegeEndYear);
        setCollegeEndMonth(profile.collegeEndMonth);
      }
      const usertoStore = {
        cardId: cardId,
        firstName: firstName,
        lastName: lastName,
        email: currentUser.email.toLowerCase(),
        phone: phone,
        college: college,
        createdAt: new Date(),
        isAdmin: false,
        visits: 0,
        isVerified: false,
        contacts: [],
        profiles: [
          {
            firstName: firstName,
            lastName: lastName,
            imageUrl: imageUrl,
            email: email.toLowerCase(),
            phone: phone,
            college: college,
            linkedIn: linkedIn,
            x: x,
            instragram: instagram,
            resume: resume,
            github: github,
            portfolio: portfolio,
            additional: additional,
            headline: headline,
            degreeType: degreeType,
            major: major,
            collegeStartYear: collegeStartYear,
            collegeStartMonth: collegeStartMonth,
            collegeEndYear: collegeEndYear,
            collegeEndMonth: collegeEndMonth,
          },
        ],
      };
      return usertoStore;
    } catch (error) {
      console.log("error:", error);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isRegistering) {
      setIsRegistering(true);
      await doCreateUserWithEmailAndPassword(email, password);

      // doSendEmailVerification()

      try {
        const userInfo = defaultUserObject(email);
        await axios.post(
          `${process.env.REACT_APP_PUBLIC_BACKEND_URL}register`,
          userInfo
        );
      } catch (error) {
        console.log("error", error);
        setIsRegistering(false);

        if (cardId) {
          navigate(`/profile/${cardId}`);
        }
      }
    }
  };

  const onGoogleRegister = async (e) => {
    e.preventDefault();
    if (!isRegistering) {
      setIsRegistering(true);
      const user = await doSignInWithGoogle().catch((err) => {
        setIsRegistering(false);
      });

      try {
        const userInfo = defaultUserObject(user.displayName, user.email);
        await axios.post(
          `${process.env.REACT_APP_PUBLIC_BACKEND_URL}google-register`,
          userInfo
        );
        const { id } = useParams();
        if (id) {
          navigate(`/profile/${id}`);
        }
      } catch (error) {
        console.log("error", error);
        setIsRegistering(false);
      }
    }
  };

  return (
    <>
      <main className="w-full py-16 h-full flex self-center place-content-center place-items-center">
        <div className="w-96 text-gray-600 space-y-5 p-4  md:border md:rounded-xl">
          <div className="text-center mb-6">
            <div className="mt-2">
              <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">
                Create a New Account
              </h3>
            </div>
          </div>
          <form onSubmit={onSubmit} className="space-y-2">
            <div>
              <label className="text-sm text-gray-600 font-bold">
                Full Name
              </label>
              <input
                type="name"
                autoComplete="name"
                required
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value);
                }}
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:blue-600  rounded-lg transition duration-300"
              />
            </div>
            <div>
              <label className="text-sm text-gray-600 font-bold">Email</label>
              <input
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value.toLowerCase());
                }}
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:blue-600  rounded-lg transition duration-300"
              />
            </div>

            <div>
              <label className="text-sm text-gray-600 font-bold">
                Password
              </label>
              <input
                disabled={isRegistering}
                type="password"
                autoComplete="new-password"
                required
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600  rounded-lg transition duration-300"
              />
            </div>

            <div>
              <label className="text-sm text-gray-600 font-bold">
                Confirm Password
              </label>
              <input
                disabled={isRegistering}
                type="password"
                autoComplete="off"
                required
                value={confirmPassword}
                onChange={(e) => {
                  setconfirmPassword(e.target.value);
                }}
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-blue-600  rounded-lg transition duration-300"
              />
            </div>

            {errorMessage && (
              <span className="text-red-600 font-bold">{errorMessage}</span>
            )}

            <button
              type="submit"
              disabled={isRegistering}
              className={`w-full px-4 py-2 text-white font-medium rounded-lg ${
                isRegistering
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700 hover:shadow-xl transition duration-300"
              }`}
            >
              {isRegistering ? "Signing Up..." : "Sign Up"}
            </button>
            <div className="text-sm text-center">
              Already have an account? {"   "}
              <Link
                to={"/login"}
                className="text-center text-sm hover:underline font-bold"
              >
                Continue
              </Link>
            </div>
            <div className="flex flex-row text-center w-full">
              <div className="border-b-2 mb-2.5 mr-2 w-full"></div>
              <div className="text-sm font-bold w-fit">OR</div>
              <div className="border-b-2 mb-2.5 ml-2 w-full"></div>
            </div>
            <button
              disabled={isRegistering}
              onClick={(e) => {
                onGoogleRegister(e);
              }}
              className={`w-full flex items-center justify-center gap-x-3 py-2.5 border rounded-lg text-sm font-medium  ${
                isRegistering
                  ? "cursor-not-allowed"
                  : "hover:bg-gray-100 transition duration-300 active:bg-gray-100"
              }`}
            >
              <svg
                className="w-5 h-5"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_17_40)">
                  <path
                    d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z"
                    fill="#34A853"
                  />
                  <path
                    d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z"
                    fill="#FBBC04"
                  />
                  <path
                    d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z"
                    fill="#EA4335"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_17_40">
                    <rect width="48" height="48" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {isRegistering ? "Registering..." : "Continue with Google"}
            </button>
          </form>
        </div>
      </main>
    </>
  );
};

export default Register;
