//formats text to have newlines (they somehow get removed in GPT's responses)
export default function NewlineText({ text, truncate=true}) {
    let newText;

    if(text){
    newText = text.split("\n").map((str) => (
        <>
            {str}
            <br />
        </>
    ));
    }
    newText = <p className={"mt-1 text-sm leading-6" + (truncate ? " line-clamp-2" : "")}>{newText}</p>;

    return newText;
}