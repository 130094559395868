import { FaPhone } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

const avatar = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="0.5"
        stroke="currentColor"
    >
        <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
    </svg>
);
  
export default function CardFront({ person, numPortfolioLinks, portfolioLinks}) {
    return (
        <div className="py-10 px-12">
        {person.imageUrl ? (
            <img
                className="mx-auto h-48 w-48 object-top object-cover rounded-3xl shadow-lg md:h-56 md:w-56"
                src={person.imageUrl}
                alt=""
            />
        ) : (
            <div className="mx-auto h-48 w-48 object-top object-cover rounded-3xl shadow-lg md:h-56 md:w-56 text-white">
                {avatar}
            </div>
        )}
        <div className="flex flex-col justify-center">
            <h2 className="mt-6 text-center text-3xl font-bold text-white">
            <span className="font-normal">{person.firstName}</span>{" "}
            {person.lastName}
            </h2>
            <p className="text-white text-center text-sm">{person.college}</p>
            {person.headline && (
            <p className="mt-4 text-center text-sm text-white bg-gray-700 self-center px-4 py-2 rounded-full border border-white">
                {person.headline}
            </p>
            )}
        </div>

        <div className="flex w-full justify-center gap-x-12 text-white mt-6 z-10">
            {person.portfolio && (
                <li className="flex flex-wrap justify-items-start gap-x-2 z-10" key={person.portfolio}>
                <button
                    // href={person.portfolio}
                    onClick={() => {window.open(person.portfolio, "_blank");}}
                    className="items-center underline underline-offset-1 text-pretty z-10"
                >
                    Website
                </button>
                </li>
            )}
            {person.resume && (
                <li className="flex justify-items-start gap-x-2" key={person.resume}>
                <button
                    onClick={() => {window.open(person.resume, "_blank");}}
                    className="items-center underline underline-offset-1 text-pretty"
                >
                    {" "}
                    Resume
                </button>
                </li>
            )}
            {numPortfolioLinks < 2 && (
                <div>
                {portfolioLinks.map(({ name, title, icon }, index) => {
                if (!person[name]) return null;

                return (
                    <li className="flex justify-items-start gap-x-2" key={index}>
                    <button
                        onClick={() => {window.open(person[name], "_blank");}}
                        className="items-center underline underline-offset-1 text-pretty"
                    >
                        {" "}
                        {title}
                    </button>
                    </li>
                );
                })}
                </div>
            )}
            </div>

        {/* Links section - only show if at least 2 links, otherwise move it to the website/resume section to make formatting look cleaner.  */}
        {numPortfolioLinks >= 2 && (
            <ul
            className={`flex ${
                person.additional ? "justify-center" : "justify-center"
            }  items-center gap-x-6 ${
                person.additional && person.headline ? "mt-4" : "mt-8"
            }`}
            >
            {portfolioLinks.map(({ name, title, icon }, index) => {
                if (!person[name]) return null;

                return (
                    <li key={index}>
                    <button
                        onClick={() => {window.open(person[name], "_blank");}}
                        className="text-white hover:text-blue-300"
                    >
                        <span className="sr-only">{title}</span>
                        {icon}
                    </button>
                    </li>
                );
                }
            )}
            </ul>
        )}

        {/* Phone number and email section - move from back to front of card if the user has not listed any skills. */}
        {!person.skills && (
            <div className="flex flex-col justify-start gap-4 mt-4 text-left">
                {person.phone && (
                <div className="flex gap-x-4 items-center">
                    <FaPhone size={20} className="text-white"/>
                    <a href={`tel:${person.phone}`} className="text-white">{person.phone}</a>
                </div>
                )}
                {person.email && (
                <div className="flex gap-x-4 items-center">
                    <IoIosMail size={20} className="text-white"/>
                    <a href={`mailto:${person.email}`} className="text-white">{person.email}</a>
                </div>
                )}
            </div>
        )}

        {person.additional && (
            <div className="flex justify-center mt-8 mb-4 text-justify text-white w-full">
                <p className="text-sm whitespace-pre-line">{person.additional}</p>
            </div>
        )}
        </div>
    );
}