import axios from "axios";

const getPresignedUrl = async (fileName, fileType) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}get_presigned_url`,
        {
          fileName,
          fileType,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error getting presigned URL", error);
      return Promise.reject(error);
    }
};

// uploads a file after getting a presigned URL
const uploadFile = async (presignedUrl, file) => {
    try {
      await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": "image",
        },
      });
    } catch (error) {
      console.error("Error uploading file", error);
      return Promise.reject(error);
    }
};

// uploads a file to s3 by first getting a presigned URL and then uploading the file
export const handleFileUpload = async (file) => {
    //Call API to Get presigned URL from backend
    const { presignedUrl, key } = await getPresignedUrl(file.name, file.type);
    
    //Call API to upload file to S3
    await uploadFile(presignedUrl, file);
    const fileUrl = `https://${process.env.REACT_APP_PUBLIC_AWS_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_PUBLIC_AWS_REGION}.amazonaws.com/${key}`;
    return fileUrl;
  };