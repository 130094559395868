import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import React, { useEffect, useState } from 'react';

export default function StreaksDashboard ({ streaksData }) {
    const [maxVisits, setMaxVisits] = useState(0);
    const [streaksBoxInfoText, setStreaksBoxInfoText] = useState(""); // text that shows how many visits on a specific day

    // end date is today
    const endDate = (new Date().toISOString()).split('T')[0];

    // startDate is 6 months ago
    const startDate = (new Date(new Date().setDate(-183)).toISOString()).split('T')[0];

    useEffect((() => {
        if (streaksData.length > 0) {
            setMaxVisits(Math.max(...streaksData.map((value) => value.count)));
        }
    }), [streaksData]);

    const onMouseOverStreaksBox = (event, value) => {
        if (!value) {
          setStreaksBoxInfoText("");
          return;
        }
    
        // format date as 21 Aug 2021
        const date = new Date(value.date);
        const formattedDate = date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
    
        setStreaksBoxInfoText(value.count + " visits on " + formattedDate);
    }
    
    const onMouseLeaveStreaksBox = () => {
        setStreaksBoxInfoText("");
    }

    return (
        <div className="mt-8 bg-white rounded-md shadow-md p-2">
        <div className="text-lg font-bold pt-2 pl-2">Profile visits</div>
        <div className="text-sm text-gray-500 pb-2 pl-2">Heatmap of visits to your profile</div>
        <CalendarHeatmap
            startDate={startDate}
            endDate={endDate}
            values={streaksData}
            classForValue={(value) => {
                if (!value) {
                    return 'color-empty';
                }
                // color based on number of visits. Can be any of [1,2,3,4]
                return `color-github-${Math.ceil(value.count / maxVisits * 4)}`;
            }}
            onMouseOver={onMouseOverStreaksBox}
            onMouseLeave={onMouseLeaveStreaksBox}
        />
        <div className="text-center text-sm text-gray-500 mt-2">
          {streaksBoxInfoText}
        </div>
      </div>
    );
}