import React from 'react'
import Feedback from './Feedback';

export default function ViewFeedback({ feedbackList = [] }) {
  if (feedbackList?.length === 0) {
    return (
      <div className="flex justify-center items-center py-4">
        <p className="text-lg font-semibold text-gray-800">No recent feedback. You can start the conversation :)</p>
      </div>
    )
  }
  
  return (
    <ul role="list" className="divide-y rounded-md mt-4 divide-gray-800 bg-white px-2">
      {feedbackList.map((feedback) => (
        <Feedback
          content={feedback.content} 
          key={feedback._id} 
        />
      ))}
    </ul>
  )
}  