import axios from "axios";

export function saveProfileChanges(newProfile, currentUser) {
  if(!currentUser || !currentUser.email) {
    throw new Error("No user email found");
  }

  return axios.post(
    `${process.env.REACT_APP_PUBLIC_BACKEND_URL}edit_profile`,
    {
      profile: newProfile,
      email: currentUser.email,
    }
  );
}

export const defaultProfile = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  college: "",
  linkedIn: "",
  x: "",
  instagram: "",
  github: "",
  portfolio: "",
  resume: "",
  additional: "",
  headline: "",
  degreeType: "",
  major: "",
  collegeStartYear: "",
  collegeStartMonth: "January",
  collegeEndYear: "",
  collegeEndMonth: "January",
};

// options a user can select for their degree type on their profile
export const degreeTypeOptions = [
  "Associate's",
  "Bachelor's",
  "Master's",
  "Doctorate",
  "PhD",
  "MBA",
  "PharMD",
  "JD",
  "MD",
  "Bootcamp",
  "Certification",
  "Other",
];

export const monthsOfYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
