import Modal from "../Components/profileForm/Modal";
import FormTemplate from "../Components/profileForm/FormTemplate";
import ProfileCard from "./ProfileCard/ProfileCard";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useParams } from "react-router-dom";

export default function CreateProfileForm({
  intro,
  setIntro,
  handleSubmit,
  user,
  modalOpen,
  setModalOpen,
  handleConfirm,
  answers,
  setAnswers,
  profilePicUrl = "",
}) {
  const { id } = useParams();
  const { setCardId } = useAuth();
  console.log(id);
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();
  if (userLoggedIn) {
    setIntro(false);
    setCardId(id);
  }
  const questions = [
    {
      id: "instructions",
      text: "Before we get started",
      description:
        "Remember to answer the questions with information you want to share with your new connections. Skip any questions you don't want to answer.",
      type: "info",
    },
    {
      id: "firstName",
      text: "What is your first name?",
      placeholder: "Ava",
      type: "open",
      required: "true",
    },
    {
      id: "lastName",
      text: "Last name",
      placeholder: "Brown",
      type: "open",
      required: "true",
    },
    {
      id: "imageUrl",
      text: "Profile Picture",
      type: "file",
      description: "Please upload a professional headshot",
      // store whether user has already uploaded a profile picture, so we can show its current value when editing
      currentUrl: profilePicUrl,
    },
    {
      id: "email",
      text: "What's your email?",
      placeholder: "abrown@gmail.com",
      type: "open",
    },
    {
      id: "phone",
      text: "Phone number",
      placeholder: "123-456-7890",
      type: "tel",
    },
    {
      id: "headline",
      text: "Headline",
      placeholder: "Ex-Microsoft intern, incoming @ Google",
      type: "open",
      maxLen: 100,
    },
    {
      id: "college",
      text: "Where do you currently go to school?",
      placeholder: "University of California, Berkeley",
      type: "open",
    },
    {
      id: "degreeType",
      text: "What type of degree are you in school for?",
      type: "close",
      options: [
        { id: "Associate's", text: "Associate's" },
        { id: "Bachelor's", text: "Bachelor's" },
        { id: "Master's", text: "Master's" },
        { id: "Doctorate", text: "Doctorate" },
        { id: "PhD", text: "PhD" },
        { id: "MBA", text: "MBA" },
        { id: "PharMD", text: "PharMD" },
        { id: "JD", text: "JD" },
        { id: "MD", text: "MD" },
        { id: "Bootcamp", text: "Bootcamp" },
        { id: "Certification", text: "Certification" },
        { id: "Other", text: "Other" },
      ],
    },
    {
      id: "major",
      text: "What's your major?",
      placeholder: "Business Administration",
      description: "If you have more than one, list them all.",
      type: "open",
    },
    {
      id: "collegeStartMonth",
      text: "In which month did you start college?",
      type: "close",
      options: [
        { id: "January", text: "January" },
        { id: "February", text: "February" },
        { id: "March", text: "March" },
        { id: "April", text: "April" },
        { id: "May", text: "May" },
        { id: "June", text: "June" },
        { id: "July", text: "July" },
        { id: "August", text: "August" },
        { id: "September", text: "September" },
        { id: "October", text: "October" },
        { id: "November", text: "November" },
        { id: "December", text: "December" },
      ],
    },
    {
      id: "collegeStartYear",
      text: "In which year did you start college?",
      placeholder: "2021",
      type: "open",
    },
    {
      id: "collegeEndMonth",
      text: "In which month do you (plan to) finish college?",
      type: "close",
      options: [
        { id: "January", text: "January" },
        { id: "February", text: "February" },
        { id: "March", text: "March" },
        { id: "April", text: "April" },
        { id: "May", text: "May" },
        { id: "June", text: "June" },
        { id: "July", text: "July" },
        { id: "August", text: "August" },
        { id: "September", text: "September" },
        { id: "October", text: "October" },
        { id: "November", text: "November" },
        { id: "December", text: "December" },
      ],
    },
    {
      id: "collegeEndYear",
      text: "In which year do you (plan to) finish college?",
      placeholder: "2026",
      type: "open",
    },
    {
      id: "linkedIn",
      text: "LinkedIn URL",
      placeholder: "https://www.linkedin.com/in/your-profile",
      type: "open",
    },
    {
      id: "xUrl",
      text: "X URL",
      placeholder: "https://www.x.com/your-profile",
      type: "open",
    },
    {
      id: "instagram",
      text: "Instagram URL",
      placeholder: "https://www.instagram.com/your-profile",
      type: "open",
    },
    {
      id: "github",
      text: "GitHub link",
      placeholder: "https://www.github.com/username",
      type: "open",
    },
    {
      id: "portfolio",
      text: "Portfolio Website",
      placeholder: "https://www.your-website.com",
      type: "open",
    },
    {
      id: "resume",
      description: "Please provide a link to your resume",
      text: "Resume",
      placeholder: "https://docs.google.com/document/...",
      type: "open",
    },
    {
      id: "additional",
      text: "Anything else you want to share on your profile?",
      description:
        "Share your experience, interests, achievements and/or favorite Spiderman actor.",
      placeholder: "Personal project links, shoutouts, media coverage etc.",
      type: "open",
      maxLen: 250,
    },
  ];
  if (intro) {
    return (
      <div className="flex flex-col h-full justify-center items-center gap-8 m-4 ">
        <div className="text-2xl font-bold text-gray-800 text-center">
          Welcome to Penpal
        </div>
        <div className="text-lg text-gray-600 text-center">
          Let's create your profile quickly so you can start connecting
        </div>
        <button
          className="rounded-2xl px-4 py-2 border-black border hover:border-blue-800 group"
          onClick={() => {
            setIntro(false);
            navigate(`/register/${id}`);
          }}
        >
          <div className="group-hover:text-blue-800">Create Profile</div>
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full justify-center items-center gap-8 m-4 md:w-2/3">
      <Modal
        title="Confirm your details"
        person={<ProfileCard person={answers} />}
        confirm={() => handleConfirm()}
        open={modalOpen}
        setOpen={setModalOpen}
      />
      <FormTemplate
        questions={questions}
        onSubmit={handleSubmit}
        answers={answers}
        setAnswers={setAnswers}
      />
    </div>
  );
}
