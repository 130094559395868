import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import ProfileCard from "../Components/ProfileCard/ProfileCard";
import CreateProfileForm from "../Components/CreateProfileForm";
import { defaultProfile } from "../scripts/Profile";

const Profile = () => {
  const { id } = useParams(); //
  const [loading, setLoading] = useState(true); // [loading, setLoading
  const [person, setPerson] = useState(false); // [person, setPerson]

  const cardId = id;
  //get user info from api
  const getUserInfo = async (id) => {
    if (id.length !== 16) {
      window.location.replace("/");
      return;
    }
    try {
      //post request with cardID

      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}get_user_by_card_id`,

        { cardId: cardId }
      );
      if (response.status === 200) {
        const data = response.data;

        const profile = data.profiles[0];

        setPerson(profile);
      }
    } catch (error) {
      //catch error

      setPerson(false);
    }
  };

  //use effect
  useEffect(() => {
    getUserInfo(id);
    //wait a second
    setTimeout(() => {
      setLoading(false);
    }, 3000);

    console.log("user company", person);
  }, [id]);

  if (loading) {
    const words = [
      "Bonjour",
      "Hello",
      "Aloha",
      "Salam",
      "Olá",
      "Namaste",
      "Konnichiwa",
      "Ciao",
    ];
    const randomWord = () => {
      return words[Math.floor(Math.random() * words.length)];
    };
    return (
      <motion.div
        className="h-full w-full lg:mt-76 mt-60 text-7xl flex justify-center items-center  font-extralight"
        id="hello"
        initial={{ opacity: 0, y: 60 }} // Start 50 pixels below the final position and with zero opacity
        viewport={{ once: true, amount: 0.5 }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.5, ease: "easeOut" },
        }} // Animate to full opacity and final position at y=0
      >
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              //type random word
              .typeString(randomWord())
              .callFunction(() => {
                console.log("String typed out!");
              })
              .pauseFor(3500)
              .deleteAll()
              .callFunction(() => {
                console.log("All strings were deleted");
              })
              .start();
          }}
        />
      </motion.div>
    );
  }

  if (!person) {
    return (
      <div className="h-full w-full lg:mt-76 mt-60">
        <CreateProfile />
      </div>
    );
  }

  return <ProfileCard person={person} />;
};

const CreateProfile = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [intro, setIntro] = useState(true);
  const [answers, setAnswers] = useState(defaultProfile);
  const { id } = useParams();

  const handleSubmit = (answers) => {
    setModalOpen(true);
    console.log("answers received", answers);
    answers = { ...answers, cardId: id };
    setAnswers(answers);
    setUser(formatAnswersToUserObject(answers));
  };

  const [user, setUser] = useState({});

  const formatAnswersToUserObject = (answers) => {
    const usertoStore = {
      cardId: id,
      firstName: answers.firstName,
      lastName: answers.lastName,
      email: answers.email,
      phone: answers.phone,
      college: answers.college,
      createdAt: new Date(),
      isAdmin: false,
      visits: 0,
      isVerified: false,
      contacts: [],
      profiles: [
        {
          firstName: answers.firstName,
          lastName: answers.lastName,
          imageUrl: answers.imageUrl,
          email: answers.email,
          phone: answers.phone,
          college: answers.college,
          linkedIn: answers.linkedIn,
          x: answers.xUrl,
          instagram: answers.instagram,
          github: answers.github,
          portfolio: answers.portfolio,
          resume: answers.resume,
          additional: answers.additional,
          headline: answers.headline,
          degreeType: answers.degreeType,
          major: answers.major,
          collegeStartYear: answers.collegeStartYear,
          collegeStartMonth: answers.collegeStartMonth,
          collegeEndYear: answers.collegeEndYear,
          collegeEndMonth: answers.collegeEndMonth,
        },
      ],
    };
    setUser(usertoStore);

    return usertoStore;
  };

  const handleConfirm = async () => {
    //post request with answers
    const usertoStore = formatAnswersToUserObject(answers);
    try {
      await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}save_user`,
        usertoStore
      );
    } catch (error) {
      console.log("error", error);
    }

    setModalOpen(false);
    window.location.reload();
  };

  return (
    <CreateProfileForm
      intro={intro}
      setIntro={setIntro}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      handleSubmit={handleSubmit}
      handleConfirm={handleConfirm}
      answers={answers}
      setAnswers={setAnswers}
    />
  );
};

export default Profile;
