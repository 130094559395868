/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'
import CardText from './CardText';
import { TextureLoader } from 'three/src/loaders/TextureLoader'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/models/card.glb');
  const cardMesh = useRef();
  const group = useRef();
  const cardTexture = useLoader(TextureLoader, '/models/cardTexturePlaster.png')

  return (
    // <group {...props} dispose={null}>
    <group ref={group} {...props} dispose={null} scale={5.0}>
      <pointLight
        intensity={1000.0}
        decay={2}
        position={[0.0, 0.5, 2.45]}
        rotation={[-1.839, 0.602, 1.932]}
      />      
      <pointLight
        intensity={1000.0}
        decay={2}
        position={[-0.0, -0.5, -2.45]}
        rotation={[1.839, -0.602, -1.932]}
      />
      <ambientLight intensity={0.5} />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Card.geometry}
        ref={cardMesh}
        // custom material color
        material-color={props.cardColor.cardColor}        
        rotation={[Math.PI / 2, -Math.PI / 2, 0]}
      >
        <meshStandardMaterial
          attach="material"
          color={props.cardColor.cardColor}
          map={cardTexture}
          roughness={1.0}
          metalness={0.2}
        />
      </mesh>
      <CardText text="hello." size={0.3} height={0.0001} color={props.cardColor.textColor} />
    </group>
  )
}

useGLTF.preload('/models/card.glb')
