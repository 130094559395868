import React from "react";
import { motion } from "framer-motion";

const Manifesto = () => {
  const manifesto = [
    {
      title: "We Build, We Care, We Love What We Do.",
      description:
        "We're a small team with big ambitions. Every one of us wears many hats, not just because we have to, but because we love to. Here, action speaks louder than words. We don't wait around for instructions or approval; we dive in headfirst.",
    },
    {
      title: "Quick Moves, Big Dreams.",
      description:
        "We make decisions fast, always ready to take a leap. Sure, risks are part of the game, but we're all about fixing on the fly and getting better with each step. Our aim? To create stuff that makes people's lives a little brighter, a little easier.",
    },
    {
      title: "Happy Users Are Our True North.",
      description:
        "When our users are happy, we know we're on the right track. Their feedback is gold, shaping our journey towards making something truly special. Quick responses, listening ears, and hearts set on improvement – that's our way.",
    },
    {
      title: "Passion Drives Us.",
      description:
        "We're in this because we love it. Coding, designing, problem-solving – it's not just work, it's our playground. We do what we love and love what we do. And we share that love through the products we build.",
    },
    {
      title: "Straight Talk, Strong Bonds.",
      description:
        "Around here, honesty rules. We're all about clear, open communication. It's how we grow, face challenges, and strengthen our team bond. No sugar-coating, just real talk and real respect for each other.",
    },
    {
      title: "Simplicity is Our Signature.",
      description:
        "We believe in keeping things sleek and simple, focusing on what really matters. Our creations stand out through minimalism and speed, made for those who appreciate quality and style.",
    },
    {
      title: "This Is Who We Are.",
      description:
        "We're not just a team; we're a family of creators, driven by passion and united by a shared mission to make cool, meaningful stuff. Welcome on board – let's make magic happen together.",
    },
  ];

  //Animation constants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5, // Delays the animation of each child by 0.2 seconds
        delayChildren: 0.5, // Delays the animation of each child by 0.2 seconds
      },
    },
  };

  // Define the children's animation variants
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    duration: 0.5,
  };

  return (
    <div className="flex flex-col p-8 gap-8 py-12 items-center justify-center  ">
      <div className="md:w-1/2 gap-8 flex flex-col">
        {" "}
        <div className="text-4xl">
          Team <span className="font-bold">Manifesto</span>
        </div>
        <motion.ul
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          className="flex flex-col gap-8"
        >
          {manifesto.map((item, index) => (
            <motion.li
              key={index}
              initial="hidden"
              whileInView="visible"
              variants={itemVariants}
              transition={{ duration: 1, ease: "anticipate" }}
              viewport={{ once: true, amount: 0.8 }}
            >
              <h2 className="font-bold  font-xl">{item.title}</h2>
              <p className="">{item.description}</p>
            </motion.li>
          ))}
        </motion.ul>
      </div>
    </div>
  );
};

export default Manifesto;
