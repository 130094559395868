import React, { useState, useEffect } from "react";
import { useAuth } from "../Components/AuthContext.js";
import axios from "axios";
import { motion } from "framer-motion";
import { Navigate } from "react-router-dom";
import ViewOrEditProfile from "../Components/ViewOrEditProfile.js";
import Loader from "../Components/Loader.js";
import StreaksDashboard from "../Components/StreaksDashboard.js";
import FeedbackSection from "../Components/FeedbackSection/FeedbackSection.js";

export default function Home() {
  const [loading, setLoading] = useState(true);
  const [person, setPerson] = useState(false);
  const [cardId, setCardId] = useState("");
  const [streaksTimeline, setStreaksTimeline] = useState("183daysAgo"); // 7daysAgo, 30daysAgo, 90daysAgo, 185daysAgo, 365daysAgo. Note: heatmap will need to be updated accordingly.
  const [streaksData, setStreaksData] = useState([]);// [{ date: "2021-09-01", count: 5 }, { date: "2021-09-02", count: 3 }, ...]

  const { currentUser } = useAuth();

  useEffect(() => {
    setLoading(true);
    if (!person) {
      getUserInfo();
    }
    //wait a second
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [currentUser]);

  useEffect(() => {
    getStreaksDashboard();
  }, [streaksTimeline]);

  const getStreaksDashboard = async() => {
    try {
      if (!currentUser || !currentUser.email) {
        return;
      }

      const streaksResponse = await getProfileVisits();
      setStreaksData(streaksResponse);
    } catch (error) {
      console.log("error showing streaks for this profile:", error);
    }
  }

  // Returns visits to the user's profile in the form:
  // [{ date: "2021-09-01", count: 5 }, { date: "2021-09-02", count: 3 }, ...]
  const getProfileVisits = async() => {
    const response = await axios.post(
      `${process.env.REACT_APP_PUBLIC_BACKEND_URL}get_profile_visits`,
      { email: currentUser.email, timeframe: streaksTimeline }
    );
    console.log("streaks data:", response.data);

    return response.data;
  }

  //get user info from api, fetched by email
  const getUserInfo = async () => {
    if (!currentUser || !currentUser.email) {
      window.location.replace("/login");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}get_profile_from_email`,
        { email: currentUser.email }
      );

      if (response.status === 200) {
        const profile = response.data.profiles[0];
        setCardId(response.data.cardId);
        setPerson(profile);
      }
    } catch (error) {
      console.log("error:", error);
      // todo: catch error
      setPerson(false);
    }
  };

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }
  function getDisplayName(currentUser) {
    if (!currentUser || !currentUser.displayName) {
      return "";
    }
    const names = currentUser.displayName.split(" ");
    return names.length > 0 ? names[0] : currentUser.displayName;
  }

  if (loading) {
    const words = [
      "Bonjour",
      "Hello",
      "Aloha",
      "Salam",
      "Olá",
      "Namaste",
      "Konnichiwa",
      "Ciao",
    ];
    const randomWord = () => {
      return words[Math.floor(Math.random() * words.length)];
    };

    return (
      <motion.div
        className="flex-col h-full w-full lg:pt-76 pt-60 flex justify-center items-center font-extralight"
        id="hello"
        initial={{ opacity: 0 }} // Start 50 pixels below the final position and with zero opacity
        viewport={{ once: true, amount: 0.5 }}
        whileInView={{
          opacity: 1,
          transition: { duration: 1, ease: "easeOut" },
        }} // Animate to full opacity and final position at y=0
      >
        <div className="flex flex-col items-start">
          {" "}
          {/* This aligns the text and the loader to the start (left) */}
          <p>
            <span className="text-3xl">
              <span className="font-bold">{randomWord()} </span>
              {currentUser.displayName ? getDisplayName(currentUser) : ""}
            </span>
            <br />
            Hold on while we load your profile.
          </p>
          <div className="flex w-full justify-center self-start -mx-4">
            <Loader height={80} width={80} />
          </div>
        </div>
      </motion.div>
    );
  } else {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen pt-8 pb-12 bg-gray-100 ml-8">
        {cardId.length > 0 ? (
          <div className="sm:flex sm:space-x-4">
            <div className="sm:w-1/3">
              <ViewOrEditProfile profile={person} setProfile={setPerson} currentUser={currentUser} />
              {streaksData.length > 0 && <StreaksDashboard streaksData={streaksData} />}
            </div>
            <div className="sm:w-1/3 mt-16 sm:mt-0 px-2">
              <FeedbackSection user={currentUser} />
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-start h-full w-2/3 lg:w-1/3 gap-8">
            {currentUser?.displayName && 
              <div className="md:text-2xl text-xl text-center">
                Hello,
                <span className="font-bold">
                  &nbsp;{getDisplayName(currentUser)}
                </span>
              </div>
            }
            <div className="text-sm md:text-lg text-start">
              <span className="font-bold">No profiles found. </span>
              Please login with the email you used when you first set up your
              card. If you have any questions, contact us at{" "}
              <a
                href={`mailto:contact@penpalplayground.com`}
                target="_blank"
                rel="noreferrer noopener"
                className="text-blue-700 hover:text-blue-800 hover:bg-blue-200 transition-all hover:px-2 hover:border-b-2 border-blue-600 py-1"
              >
                contact@penpalplayground.com.
              </a>
              <button className="flex flex-row justify-center text-blue-700 hover:text-blue-900  items-center gap-2 my-8 bg-blue-200 hover:bg-blue-300 px-4 py-2 rounded-md self-center transition-all">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-3 h-3"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
                <a href="/shop" className="text-xs font-medium  transition-all">
                  Purchase a card
                </a>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
