import React from "react";
import { motion } from "framer-motion";

const Lens = () => {
  return (
    <div
      className="flex flex-col h-full w-full overflow-x-hidden   md:pt-8 pt-4 transition-all duration-200"
      id="Lens"
    >
      <Problem />
      <LensImage />
      <LensApp />
    </div>
  );
};

const Problem = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5, // Delays the animation of each child by 0.2 seconds
        delayChildren: 0.35, // Delays the animation of each child by 0.2 seconds
        transition: { duration: 1, ease: "circInOut" },
      },
    },
  };

  // Define the children's animation variants
  const itemVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0 },
    transition: { duration: 1, ease: "anticipate" },
  };
  return (
    <motion.div
      id="Lens"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: { duration: 1, ease: "anticipate" },
      }}
      viewport={{ once: true, amount: 0.5 }}
      className="flex flex-col h-screen bg-black p-10 justify-center items-center"
    >
      <div className="flex text-white flex-col gap-2 justify-center text-2xl md:text-4xl lg:w-1/3 md:w-2/3 items-center text-center">
        Ever find yourself with a mountain of business cards after an event and
        it takes you
        <motion.ul
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          className="flex justify-center text-6xl px-4 py-1  bg-blue-200 border-b-2 border-blue-400 text-black  font-bold"
        >
          l
          <motion.li
            variants={itemVariants}
            transition={{ duration: 1, ease: "circIn", delay: 1.5 }}
          >
            o
          </motion.li>
          <motion.li
            variants={itemVariants}
            transition={{ duration: 1, ease: "circIn" }}
          >
            o
          </motion.li>
          <motion.li
            variants={itemVariants}
            transition={{ duration: 1, ease: "circIn" }}
          >
            o
          </motion.li>
          <motion.li
            variants={itemVariants}
            transition={{ duration: 1, ease: "circIn" }}
          >
            o
          </motion.li>
          <motion.li
            variants={itemVariants}
            transition={{ duration: 1, ease: "circIn" }}
          >
            o
          </motion.li>
          <motion.li
            variants={itemVariants}
            transition={{ duration: 1, ease: "circIn" }}
          >
            o
          </motion.li>
          <motion.li
            variants={itemVariants}
            transition={{ duration: 1, ease: "circIn" }}
          >
            n
          </motion.li>
          <motion.li
            variants={itemVariants}
            transition={{ duration: 1, ease: "circIn" }}
          >
            g
          </motion.li>
        </motion.ul>
        to type and save all the new connections?
      </div>
    </motion.div>
  );
};

const LensImage = () => {
  return (
    <motion.div
      id="Lens"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: { duration: 1, ease: "circInOut" },
      }}
      viewport={{ once: true, amount: 0.5 }}
      className="flex relative flex-col min-h-screen bg-white bg-opacity-50 pt-10 lg:pt-20 justify-start items-center "
    >
      <div className="text-black font-semibold text-3xl sm:text-4xl md:text-6xl bg-white p-4 z-10">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 1, ease: "circInOut", delay: 2 },
          }}
          viewport={{ once: true, amount: 1, delay: 1 }}
          className="bg-gradient-to-br from-blue-400 to-blue-800 bg-clip-text text-transparent lg:text-8xl sm:text-6xl text- font-bold  justify-center  items-start w-full flex flex-col gap-8"
        >
          Penpal Lens
          <br />
        </motion.div>
      </div>

      <div className="absolute h-full z-0 bottom-0 pt-24">
        {" "}
        <img
          src={require("../Assets/img/scanning.jpeg")}
          alt=""
          className="hidden md:block h-full object-cover object-center"
        />
        <img
          src={require("../Assets/img/scanning.jpg")}
          alt=""
          className="md:hidden h-full object-cover object-center"
        />
      </div>
    </motion.div>
  );
};

const LensApp = () => {
  return (
    <motion.div
      id="card"
      className=" h-auto  w-full min-h-screen bg-black  flex flex-col items-center justify-center gap-8 p-8 py-20"
    >
      <div className="flex flex-col gap-8 md:gap-16 md:w-1/3">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "circInOut" },
          }}
          viewport={{ once: true, amount: 0.5 }}
          className="bg-gradient-to-br from-blue-400 to-blue-800 bg-clip-text text-transparent items-start lg:text-8xl text-6xl font-bold  justify-center  flex flex-col gap-8"
        >
          Penpal Lens
          <br />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "circInOut" },
          }}
          viewport={{ once: true, amount: 0.75 }}
          className="font-normal flex-grow-0 text-white text-lg lg:text-2xl py-2"
        >
          Uses AI to instantly convert business cards into neatly organized
          digital contacts. All you need to do is take a picture of the card and
          the app does the rest.
        </motion.div>
        <button className="text-white bg-blue-800 self-start px-4 py-2 rounded-lg text-lg lg:text-xl hover:bg-blue-700">
          Coming soon
        </button>
      </div>
    </motion.div>
  );
};

export default Lens;
