import { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import Model from './Model'
import * as THREE from 'three';
import CameraControls from 'camera-controls';

CameraControls.install( { THREE: THREE } );

export default function CardRenderer({ width=96, height=96, cardColor={cardColor: "#000000", textColor: "#ffffff"} }) {
  const camera = new THREE.PerspectiveCamera( 75, width / height, 0.1, 1000 );
  const controls = new CameraControls( camera );

  return (
    <div className={`h-${width} w-${height}`}>
      {/* <Canvas camera={{ fov: 75, near: 0.1, far: 1000, position: [0, 0, 10] }}> */}
        <Suspense fallback={<div>Hi there!</div>}>
      <Canvas camera={camera} controls={controls}>
          <Model cardColor={cardColor} />
          {/* <OrbitControls 
            enableZoom={false}
            enablePan={false}
            // set rotate speed
            rotateSpeed={0.3}
            autoRotate={true}
            autoRotateSpeed={3.0}
          /> */}
      </Canvas>
        </Suspense>
    </div>
  )
}