import React from "react";

const Footer = () => {
  return (
    <div className="flex text-blue-200 justify-center w-full bg-black text-xs">
      <p className="m-4">
        <a
          className=" font-bold hover:text-blue-50 transition-all"
          href="https://penpal.cards"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          PenPal{" "}
        </a>{" "}
        &copy; 2024. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
