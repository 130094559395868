import { extend } from '@react-three/fiber';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

// import font from "../../Assets/fonts/Roboto Black_Regular.json"

extend({ TextGeometry });

export default function CardText({ text, size=1, height=0.1, color="#000000" }) {
    const loader = new FontLoader();
    const fontJson = require("../../Assets/fonts/Roboto Black_Regular.json");
    const font = loader.parse(fontJson);

    return (
        <mesh position={[-0.8, 0.03, 0.0]}>
            <textGeometry attach="geometry" args={[text, { font, size, height }]} />
            <meshBasicMaterial attach="material" color={color} />
        </mesh>
    )
}