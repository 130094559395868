// Cards that show a user their own profile, and allow them to edit it
import { FaGithub, FaLinkedin, FaPhone, FaPen, FaTwitter, FaInstagramSquare } from "react-icons/fa";
import { IoMdBuild, IoIosPaper, IoIosMail } from "react-icons/io";
import EditProfileModal from "./EditProfileModal";
import React, { useState, useEffect } from "react";

const defaultAvatar = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="0.5"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    />
  </svg>
);

export default function ViewOrEditProfile({ profile, setProfile, currentUser }) {
    const [editProfileSectionModalOpen, setEditProfileSectionModalOpen] = useState(false);
    const [editProfileModalTitle, setEditProfileModalTitle] = useState("Edit profile");
    const [sectionNameToEdit, setSectionNameToEdit] = useState("")
    // stores the state of values being edited in the profile form. This is so we don't overwrite the profile object until the user clicks save
    const [profileEdited, setProfileEdited] = useState({})

    useEffect(() => {
        resetProfileEdited();
    }, []);

    // resets the profileEdited object to the current profile object
    function resetProfileEdited() {
        setProfileEdited(
            {
                firstName: profile.firstName,
                lastName: profile.lastName,
                imageUrl: profile.imageUrl,
                college: profile.college,
                degreeType: profile.degreeType,
                major: profile.major,
                collegeStartYear: profile.collegeStartYear,
                collegeStartMonth: profile.collegeStartMonth,
                collegeEndYear: profile.collegeEndYear,
                collegeEndMonth: profile.collegeEndMonth,
                linkedIn: profile.linkedIn,
                github: profile.github,
                portfolio: profile.portfolio,
                resume: profile.resume,
                email: profile.email,
                phone: profile.phone,
                headline: profile.headline,
                additional: profile.additional,
                x: profile.x,
                instagram: profile.instagram,
                skills: profile.skills,
            }
        );
    }

    const portfolioLinks = [
        { name: "LinkedIn", url: profile.linkedIn, icon: <FaLinkedin size={32} />},
        { name: "GitHub", url: profile.github, icon: <FaGithub size={32} />},
        { name: "Portfolio", url: profile.portfolio, icon: <IoMdBuild size={32} /> },
        { name: "Resume", url: profile.resume, icon: <IoIosPaper size={32} />},
        { name: "Instagram", url: profile.instagram, icon: <FaInstagramSquare size={32} />},
        { name: "X", url: profile.x, icon: <FaTwitter size={32} />},
    ]

    // called when user changes a profile field by, e.g., typing in an input field
    function updateEditedProfileField (field, value) {
        setProfileEdited({ ...profileEdited, [field]: value });
    }

    const NameAndPhotoCard = () => {
        return (
            <div className="flex flex-col bg-white rounded-md p-8 items-center justify-center w-full shadow-md">
                {profile.imageUrl ? (
                    <img
                        className="mx-auto h-40 w-40 object-top object-cover rounded-3xl shadow-lg md:h-40 md:w-40"
                        src={profile.imageUrl}
                        alt=""
                    />
                    ) : (
                        <div className="w-24 h-24 bg-gray-200 rounded-full flex items-center justify-center">
                            {defaultAvatar}
                        </div>
                )}
                <div className="text-xl font-bold mt-2">{profile.firstName} {profile.lastName}</div>
                <div className="text-md text-gray-600 mb-2">{profile.headline}</div>
                {profile.additional && (<div className="w-full">
                    <hr className="h-px border-t-0 bg-gray-200 opacity-100" />
                    <div className="text-sm text-gray-600 my-2 whitespace-pre-line text-left">{profile.additional}</div>
                </div>)}

                {/* Skills section */}
                <div className="flex flex-wrap gap-2 mt-8">
                    {profile?.skills?.map((skill, index) => (
                        <li key={index} className="flex justify-center rounded-full bg-[#525151] text-white border text-xs p-3">
                            {skill}
                        </li>
                    ))}
                </div>

                <button 
                    className="text-gray-500 hover:text-gray-800 mt-8"
                    onClick={(e) => {
                        e.preventDefault();
                        resetProfileEdited();
                        setSectionNameToEdit("nameAndPhoto");
                        setEditProfileSectionModalOpen(true);
                        setEditProfileModalTitle("Edit profile");
                    }}
                ><FaPen size={16} /></button>
            </div>
        );
    }

    const EducationCard = () => {
        return (
            <div className="bg-white rounded-md p-4 gap-2 w-full shadow-md">
                <div className="flex justify-between items-center w-full">
                    <div className="text-lg font-bold">Education</div>
                    <button 
                        className="text-gray-500 hover:text-gray-800"
                        onClick={(e) => {
                            e.preventDefault();
                            resetProfileEdited();
                            setSectionNameToEdit("education");
                            setEditProfileSectionModalOpen(true);
                            setEditProfileModalTitle("Edit education");
                        }}
                    >
                        <FaPen size={16} />
                    </button>
                </div>
                <div className="text-md text-gray-800 font-semibold">{profile.college}</div>
                <div className="text-sm text-gray-800">{(profile.degreeType && profile.major) ? `${profile.degreeType}, ${profile.major}` : `${profile.degreeType}${profile.major}`}</div>
                <div className="text-sm text-gray-800">{(profile.collegeStartYear && profile.collegeEndYear) && `${profile.collegeStartYear} - ${profile.collegeEndYear}`}</div>
            </div>
        );
    }

    const PortfolioCard = () => {
        return (
            <div className="bg-white rounded-md p-4 gap-2 w-full shadow-md">
                <div className="flex justify-between items-center w-full">
                    <div className="text-lg font-bold">Portfolio</div>
                    <button 
                        className="text-gray-500 hover:text-gray-800"
                        onClick={(e) => {
                            e.preventDefault();
                            resetProfileEdited();
                            setSectionNameToEdit("portfolio");
                            setEditProfileSectionModalOpen(true);
                            setEditProfileModalTitle("Edit portfolio");
                        }}
                    >
                        <FaPen size={16} />
                    </button>
                </div>
                {portfolioLinks.map((link, index) => (
                    <div className="flex items-center gap-2 my-4 break-all" key={index}>
                        {link.icon}
                        <div className="flex flex-col">
                            <span className="text-md text-gray-800 font-semibold">{link.name}</span>
                            <a href={link.url} target="_blank" rel="noreferrer noopener" className="text-sm text-gray-800">{link.url}</a>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    const ContactCard = () => {
        return (
            <div className="bg-white rounded-md p-4 gap-2 w-full shadow-md">
                <div className="flex justify-between items-center w-full">
                    <div className="text-lg font-bold">Contact</div>
                    <button 
                        className="text-gray-500 hover:text-gray-800"
                        onClick={(e) => {
                            e.preventDefault();
                            resetProfileEdited();
                            setSectionNameToEdit("contact");
                            setEditProfileSectionModalOpen(true);
                            setEditProfileModalTitle("Edit contact info");
                        }}
                    >
                        <FaPen size={16} />
                    </button>
                </div>
                <div className="flex items-center gap-2 my-4 break-all">
                    <IoIosMail size={20} />
                    <div className="text-md text-gray-800">{profile.email}</div>
                </div>
                <div className="flex items-center gap-2 my-4">
                    <FaPhone size={20} />
                    <div className="text-md text-gray-800">{profile.phone}</div>
                </div>
            </div>
        );
    }

    return <div className="flex flex-col items-center gap-4 max-w-sm">
        <EditProfileModal 
            open={editProfileSectionModalOpen} 
            setOpen={setEditProfileSectionModalOpen}
            title={editProfileModalTitle}
            profileEdited={profileEdited}
            setProfileEdited={setProfileEdited}
            updateEditedProfileField={updateEditedProfileField}
            sectionNameToEdit={sectionNameToEdit}
            currentUser={currentUser}
            setProfile={setProfile}
        />
        <NameAndPhotoCard />
        <EducationCard />
        <PortfolioCard />
        <ContactCard />
    </div>

}