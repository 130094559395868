import React, { useState, useEffect, useRef } from "react";
import CardFront from "./CardFront";
import CardBack from "./CardBack";

export default function ProfileCard({ person }) {
  // Number of links, e.g. linkedin, github etc. that this person has. Used to determine where to place links section.
  const [numPortfolioLinks, setNumPortfolioLinks] = useState(0);
  // Which side of the card is currently being shown. Front or back.
  // Set to undefined initially so that both sides are rendered until we get the component height, and then hide one of the sides.
  const [showingBackOfCard, setShowingBackOfCard] = useState();
  // show back of card after delay, to prevent it being shown backwards during the card flip animation
  // 0 = front and back, 1 = front, 2 = back, 3 = neither front nor back. Need this many options because both need to be rendered initially to get the component height.
  const [showingBackOfCardAfterDelay, setShowingBackOfCardAfterDelay] = useState(0);
  const [cardHeight, setCardHeight] = useState("auto");
  const [cardWidth, setCardWidth] = useState("auto");
  const cardRef = useRef(null);

  const cardFlipAnimLengthMs = 500;

  useEffect(() => {
    let numLinks = 0;
    if (person.linkedIn) numLinks++;
    if (person.github) numLinks++;
    if (person.instagram) numLinks++;
    if (person.x) numLinks++;
    setNumPortfolioLinks(numLinks);
    adjustCardHeightAndWidth();
  }, [person]);

  const portfolioLinks = [
    {
      name: "linkedIn",
      title: "LinkedIn",
      icon:
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="currentColor"
        className="h-5 w-5"
        viewBox="0 0 24 24"
      >
        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
      </svg>
    },
    {
      name: "github",
      title: "GitHub",
      icon:
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
      </svg>
    },
    {
      name: "instagram",
      title: "Instagram",
      icon:
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
      </svg>
    },
    {
      name: "x",
      title: "X",
      icon:
      <svg
        className="h-5 w-5"
        aria-hidden="true"
        target="_blank"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
      </svg>
    }
  ];

  const cardClicked = (e) => {
    e.preventDefault();

    if (!person.skills) return;

    setShowingBackOfCardAfterDelay(3);

    // Show back of card after delay, to prevent it being shown backwards during the card flip animation
    setTimeout(() => {
      if (showingBackOfCard === false) {
        setShowingBackOfCardAfterDelay(2);
      } else {
        setShowingBackOfCardAfterDelay(1);
      }
    }, cardFlipAnimLengthMs);

    setShowingBackOfCard(!showingBackOfCard);
  }

  function adjustCardHeightAndWidth() {
    const frontHeight = cardRef.current.querySelector(".front-content").clientHeight;
    const frontWidth = cardRef.current.querySelector(".front-content").clientWidth;
    const backHeight = person.skills ? cardRef.current.querySelector(".back-content").clientHeight : 0;
    const backWidth = person.skills ? cardRef.current.querySelector(".back-content").clientWidth : 0;
    const maxHeight = Math.max(frontHeight, backHeight);
    const maxWidth = Math.max(frontWidth, backWidth);

    setCardHeight(`${maxHeight}px`);
    setCardWidth(`${maxWidth}px`);
    setShowingBackOfCard(false);
    setShowingBackOfCardAfterDelay(1);
  }

  console.log("profile card person:", person);

  const getImageType = (imageUrl) => {
    const extension = imageUrl.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
        return "JPEG";
      case "png":
        return "PNG";
      case "gif":
        return "GIF";
      default:
        return "JPEG"; // Default to JPEG if you cannot determine
    }
  };

  function downloadVCard(person) {
    const firstName = person.firstName || "";
    const lastName = person.lastName || "";
    const fullName = person.firstName + person.lastName || "";
    const telephone = person.phone || "";
    const email = person.email || "";
    const linkedIn = person.linkedIn || "";
    const x = person.x || "";
    const github = person.github || "";
    const instagram = person.instagram || "";
    const portfolio = person.portfolio || "";
    const imageUrl = person.imageUrl || "";
    const imageType = getImageType(imageUrl);
    const resume = person.resume || "";
    const headline = person.headline || "";

    const vCardData = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${lastName};${firstName};;;`, // Surname; Given name; Additional names; Honorific prefixes; Honorific suffixes
      `FN:${fullName}`,
      `TITLE:${headline}`,
      `TEL;TYPE=CELL:${telephone}`,
      `EMAIL:${email}`,
      `URL:${portfolio}`,
      `X-SOCIALPROFILE;type=linkedin;x-user=${linkedIn}`,
      `PHOTO;TYPE=${imageType};VALUE=URI:${imageUrl}`,
      "END:VCARD",
    ].join("\r\n");

    const blob = new Blob([vCardData], { type: "text/vcard" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = `${person.firstName}.vcf`; // Name of the file to download
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  return (
    // <div className="flex flex-col h-full items-center min-h-screen bg-gradient-to-br from-[#e0d9d9] to-[#585858]">
    <div className="flex flex-col h-full items-center min-h-screen bg-gray-100">
      <button className="group [perspective:1000px] flex justify-center" onClick={cardClicked}>
        <div 
          ref={cardRef}
          // className={`flex-col rounded-2xl bg-gradient-to-br from-[#cec9ca] to-[#a4a3a1] justify-between max-w-md shadow-2xl mt-8 border-white border transition-all duration-${cardFlipAnimLengthMs} [transform-style:preserve-3d] ${showingBackOfCard && "[transform:rotateY(180deg)]"}`}
          className={`flex-col rounded-2xl bg-black justify-between max-w-md shadow-2xl mt-8 border-white border transition-all duration-${cardFlipAnimLengthMs} [transform-style:preserve-3d] ${showingBackOfCard && "[transform:rotateY(180deg)]"}`}
          style={{ height: cardHeight, width: cardWidth }}
        >            
          <div className="[transform:rotateY(180deg)] back-content">
            {/* Need to check if back of card is undefined because it both sides are rendered intially until we get the component height, and then hide one of the sides. */}
            {(showingBackOfCardAfterDelay === 0 || showingBackOfCardAfterDelay === 2) && <CardBack person={person} numPortfolioLinks={numPortfolioLinks} portfolioLinks={portfolioLinks} /> }
          </div>
          {/* <CardBack person={person} numPortfolioLinks={numPortfolioLinks} portfolioLinks={portfolioLinks} /> */}
          <div className="[backface-visibility:hidden] front-content">
          {(showingBackOfCardAfterDelay === 0 || showingBackOfCardAfterDelay === 1) && <CardFront person={person} numPortfolioLinks={numPortfolioLinks} portfolioLinks={portfolioLinks} />}
          </div>
        </div>
        </button>

      {/* Tap to see more text */}
      {person.skills && <div>
        {!showingBackOfCard ? (
          <button className="flex flex-col items-center mt-4" onClick={cardClicked}>
            <p className="text-black text-sm">Tap to see more</p>
          </button>
        ) : (
          <button className="flex flex-col items-center mt-4" onClick={cardClicked}>
            <p className="text-black text-sm">Tap to return</p>
          </button>
        )}
      </div>}

      {/* Save contact button */}
      <div className="flex md:flex-row gap-4 mt-8">
        <button
          className="rounded-full p-4 border border-white text-white group bg-[#525151] hover:bg-gray-800 transition-all group"
          onClick={() => downloadVCard(person)}
        >
          <div className="group-hover:text-white transition-all duration-100">
            Save contact
          </div>
        </button>
      </div>
    </div>
  );
}
