import React from "react";
import { motion } from "framer-motion";

export default function Cloud() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.35, // Delays the animation of each child by 0.2 seconds
        // Delays the animation of each child by 0.2 seconds
      },
    },
  };

  // Define the children's animation variants
  const itemVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0 },
    duration: 0.31,
  };

  const logos = [
    {
      name: "University of Rochester",
      src: require("../Assets/img/logoCloud/University-of-Rochester.png"),
      alt: "University of Rochester",
      width: 158,
      height: 48,
    },
    {
      name: "Yale",
      src: require("../Assets/img/logoCloud/Yale.png"),
      alt: "Yale",
      width: 100,
      height: 40,
    },
    {
      name: "RIT",
      src: require("../Assets/img/logoCloud/RIT.png"),
      alt: "Rochester Institute of Technology",
      width: 158,
      height: 48,
    },
    {
      name: "Apple",
      src: require("../Assets/img/logoCloud/Apple.png"),
      alt: "Apple",
      width: 100,
      height: 48,
    },
    {
      name: "Google",
      src: require("../Assets/img/logoCloud/Google.png"),
      alt: "Google",
      width: 158,
      height: 48,
    },
    {
      name: "Atlassian",
      src: require("../Assets/img/logoCloud/Atlassian.png"),
      alt: "Atlassian",
      width: 158,
      height: 48,
    },
    {
      name: "Dell",
      src: require("../Assets/img/logoCloud/Dell.png"),
      alt: "Dell",
      width: 100,
      height: 48,
    },
  ];
  return (
    <motion.div
      id="logoCloud"
      initial={{ opacity: 0, y: 60 }} // Start 50 pixels below the final position and with zero opacity
      viewport={{ once: true, amount: 0.5 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, ease: "easeOut" },
      }} // Animate to full opacity and final position at y=0
      className="bg-white py-12"
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg leading-8 text-gray-900">
          Used by{" "}
          <span className="font-bold bg-blue-50 text-blue-800 border-b border-blue-200">
            students
          </span>{" "}
          and{" "}
          <span className="font-bold bg-pink-50 text-pink-600 border-b border-pink-200  ">
            professionals
          </span>{" "}
          from leading institutions
        </h2>
        {/*Map each logo */}
        <motion.ul
          className=" flex flex-wrap justify-center items-center gap-8 mt-8"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
        >
          {logos.map((logo, index) => (
            <motion.li
              key={index}
              variants={itemVariants}
              className="items-center justify-center"
              transition={{ duration: 1, ease: "anticipate" }}
            >
              <img
                key={index}
                src={logo.src}
                alt={logo.alt}
                width={logo.width}
                className="hover:scale-105 transition-all duration-100 ease-in-out"
                height={logo.height}
              />
            </motion.li>
          ))}
        </motion.ul>
        <motion.div
          initial={{ opacity: 0, y: 60 }} // Start 50 pixels below the final position and with zero opacity
          viewport={{ once: true, amount: 0.5, delay: 2 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 1, ease: "easeOut", delay: 1.5 },
          }} // Animate to full opacity and final position at y=0
          className="my-20 text-center text-xl md:text-xl text-gray-900 flex flex-col items-center justify-center gap-8"
        >
          <div className="bg-gradient-to-br from-gray-400 h-full to-gray-800 bg-clip-text text-transparent text-2xl font-bold justify-center items-center w-full ">
            Because of its simplicity.
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}
