import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FaPen } from "react-icons/fa";
import { handleFileUpload } from "../scripts/S3";
import React, { useState } from 'react';
import { saveProfileChanges, degreeTypeOptions, monthsOfYear } from "../scripts/Profile";
import Loader from "./Loader";
import { FaPlus } from "react-icons/fa6";
import { FaTrash } from "react-icons/fa";

const EditNameAndProfileContent = ({ profileEdited, updateEditedProfileField }) => {
    // new skill that the user is currently typing in the input field
    const [skillBeingAdded, setSkillBeingAdded] = useState("");

    return (
        <div className="flex flex-col gap-4">
            {profileEdited.imageUrl && (
                <div className="flex w-full justify-center">
                <img
                    className="h-24 w-24 object-top object-cover rounded-3xl shadow-lg md:h-24 md:w-24"
                    src={profileEdited.imageUrl}
                    alt="Profile pic"
                />
                </div>
            )}
            <input
                type="file"
                onChange={async (e) => {
                    const file = e.target.files[0];
                    if (file) {
                        // Call function to handle the file upload process
                        const profilePicUrl = await handleFileUpload(file);
                        updateEditedProfileField("imageUrl", profilePicUrl);
                    }
                }}
                className="border p-2 rounded-md"
            />

            <div className="flex flex-col md:flex-row md:justify-between md:gap-x-4">
                <div className="mt-4">
                    <label className="text-md text-gray-800 font-semibold">First name</label>
                    <input
                        type="text"
                        placeholder="First name"
                        value={profileEdited.firstName}
                        onChange={e => {
                            e.preventDefault()
                            updateEditedProfileField("firstName", e.target.value)
                        }}
                        className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                    />
                </div>
                <div className="mt-4">
                    <label className="text-md text-gray-800 font-semibold">Last name</label>
                    <input
                        type="text"
                        placeholder="Last name"
                        value={profileEdited.lastName}
                        onChange={e => {
                            e.preventDefault()
                            updateEditedProfileField("lastName", e.target.value)
                        }}
                        className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                    />
                </div>
            </div>
            <div className="mt-4">
                <label className="text-md text-gray-800 font-semibold">Headline</label>
                <input
                    type="text"
                    placeholder="Ex-Microsoft intern, incoming @ Google"
                    value={profileEdited.headline}
                    maxLength={100}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("headline", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
                <div className="text-right text-sm text-neutral-500">
                    {profileEdited.headline.length}/{100}
                </div>
            </div>
            <div className="mt-4">
                <label className="text-md text-gray-800 font-semibold">About</label>
                <span className="text-sm text-gray-500"><br/>Share your experience, achievements and/or favorite Spiderman actor.</span>
                <textarea
                    type="text"
                    rows={4}
                    placeholder="Personal project links, shoutouts, media coverage etc."
                    value={profileEdited.additional}
                    maxLength={250}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("additional", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
                <div className="text-right text-sm text-neutral-500">
                    {profileEdited.additional.length}/{250}
                </div>
            </div>
            <div className="mt-4">
                <label className="text-md text-gray-800 font-semibold">Skills and interests</label>
                {/* <span className="text-sm text-gray-500"><br/>List some skills or hobbies, e.g. project management, design, cooking, etc.</span> */}
                <div className="flex items-center gap-2 mt-2">
                    <input 
                        className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight border-black w-full outline-none outline-transparent text-md text-black"
                        placeholder="Project management, design, cooking, etc."
                        value={skillBeingAdded}
                        onChange={e => {
                            e.preventDefault()
                            setSkillBeingAdded(e.target.value)
                        }}
                    />
                    <button 
                        className="flex justify-center items-center gap-x-1 rounded-md bg-white text-black border border-black text-sm p-2"
                        onClick={() => {
                            if (skillBeingAdded) {
                                updateEditedProfileField("skills", [...profileEdited.skills, skillBeingAdded]);
                                setSkillBeingAdded("");
                            }
                        }}
                    >
                        <FaPlus/> Add
                    </button>
                </div>
                <div className="flex flex-wrap gap-2 mt-4">
                    {profileEdited.skills?.map((skill, index) => (
                        <li key={index} className="group flex justify-center rounded-full bg-[#525151] text-white border text-sm p-2">
                            <button 
                                className="px-2 hover:text-gray-400 text-white hidden group-hover:block"
                                onClick={() => {
                                    const newSkills = profileEdited.skills.filter((_, i) => i !== index);
                                    updateEditedProfileField("skills", newSkills);
                                }}
                            >
                                <FaTrash size={12} />
                            </button>
                            {skill}
                        </li>
                    ))}
                </div>
            </div>
        </div>
    );
}

const EditEducationContent = ({ profileEdited, updateEditedProfileField }) => {
    return (
        <div className="flex flex-col gap-4">
            <div className="mt-2">
                <label className="text-md text-gray-800 font-semibold">College</label>
                <input
                    type="text"
                    placeholder="College"
                    value={profileEdited.college}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("college", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
            </div>
            <div className="mt-2">
                <label className="text-md text-gray-800 font-semibold">Degree type</label>
                {/* Degree dropdown */}
                <select
                    value={profileEdited.degreeType}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("degreeType", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                >
                    {degreeTypeOptions.map((degree, index) => (
                        <option key={index} value={degree}>{degree}</option>
                    ))}
                </select>
            </div>
            <div className="mt-2">
                <label className="text-md text-gray-800 font-semibold">Major</label>
                <input
                    type="text"
                    placeholder="Major"
                    value={profileEdited.major}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("major", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
            </div>
            <div className="flex flex-col md:flex-row md:justify-between md:gap-x-4">
                <div className="mt-2">
                    <label className="text-md text-gray-800 font-semibold">Start month</label>
                    <select
                        value={profileEdited.collegeStartMonth}
                        onChange={e => {
                            e.preventDefault()
                            updateEditedProfileField("collegeStartMonth", e.target.value)
                        }}
                        className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                    >
                        {monthsOfYear.map((month, index) => (
                            <option key={index} value={month}>{month}</option>
                        ))}
                    </select>
                </div>
                <div className="mt-2">
                    <label className="text-md text-gray-800 font-semibold">Start year</label>
                    <input
                        type="text"
                        placeholder="Start year"
                        value={profileEdited.collegeStartYear}
                        onChange={e => {
                            e.preventDefault()
                            updateEditedProfileField("collegeStartYear", e.target.value)
                        }}
                        className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                    />
                </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-between md:gap-x-4">
                <div className="mt-2">
                    <label className="text-md text-gray-800 font-semibold">End month</label>
                    <select
                        value={profileEdited.collegeEndMonth}
                        onChange={e => {
                            e.preventDefault()
                            updateEditedProfileField("collegeEndMonth", e.target.value)
                        }}
                        className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                    >
                        {monthsOfYear.map((month, index) => (
                            <option key={index} value={month}>{month}</option>
                        ))}
                    </select>
                </div>
                <div className="mt-2">
                    <label className="text-md text-gray-800 font-semibold">
                        End year
                    </label>
                    <input

                        type="text"
                        placeholder="End year"
                        value={profileEdited.collegeEndYear}
                        onChange={e => {
                            e.preventDefault()
                            updateEditedProfileField("collegeEndYear", e.target.value)
                        }}
                        className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                    />
                </div>
            </div>
        </div>
    );
}

const EditPortfolioContent = ({ profileEdited, updateEditedProfileField }) => {
    return (
        <div className="flex flex-col gap-4">
            <div className="mt-2">
                <label className="text-md text-gray-800 font-semibold">LinkedIn URL</label>
                <input
                    type="text"
                    placeholder="www.linkedin.com/in/your-profile"
                    value={profileEdited.linkedIn}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("linkedIn", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
            </div>
            <div className="mt-2">
                <label className="text-md text-gray-800 font-semibold">GitHub URL</label>
                <input
                    type="text"
                    placeholder="www.github.com/your-profile"
                    value={profileEdited.github}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("github", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
            </div>
            <div className="mt-2">
                <label className="text-md text-gray-800 font-semibold">Portfolio URL</label>
                <input
                    type="text"
                    placeholder="www.your-website.com"
                    value={profileEdited.portfolio}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("portfolio", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
            </div>
            <div className="mt-2">
                <label className="text-md text-gray-800 font-semibold">Resume URL</label>
                <input
                    type="text"
                    placeholder="www.drive.google.com/your-resume"
                    value={profileEdited.resume}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("resume", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
            </div>
            <div className="mt-2">
                <label className="text-md text-gray-800 font-semibold">Instagram URL</label>
                <input
                    type="text"
                    placeholder="www.instagram.com/your-profile"
                    value={profileEdited.instagram}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("instagram", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
            </div>
            <div className="mt-2">
                <label className="text-md text-gray-800 font-semibold">X URL</label>
                <input
                    type="text"
                    placeholder="www.x.com/your-profile"
                    value={profileEdited.x}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("x", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
            </div>
        </div>
    );
}

const EditContactContent = ({ profileEdited, updateEditedProfileField }) => {
    return (
        <div className="flex flex-col gap-4">
            <div className="mt-2">
                <label className="text-md text-gray-800 font-semibold">Email</label>
                <input
                    type="text"
                    placeholder="Email"
                    value={profileEdited.email}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("email", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
            </div>
            <div className="mt-2">
                <label className="text-md text-gray-800 font-semibold">Phone</label>
                <input
                    type="text"
                    placeholder="Phone"
                    value={profileEdited.phone}
                    onChange={e => {
                        e.preventDefault()
                        updateEditedProfileField("phone", e.target.value)
                    }}
                    className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
                />
            </div>
        </div>
    );
}

export default function EditProfileModal({ open, setOpen, title, profileEdited, currentUser, setProfile, updateEditedProfileField, sectionNameToEdit}) {
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null)

  let content;

  if (sectionNameToEdit === "nameAndPhoto") {
    content = <EditNameAndProfileContent 
        profileEdited={profileEdited} 
        updateEditedProfileField={updateEditedProfileField} 
    />;
  } else if (sectionNameToEdit === "education") {
    content = <EditEducationContent
        profileEdited={profileEdited}
        updateEditedProfileField={updateEditedProfileField}
    />;
  } else if (sectionNameToEdit === "portfolio") {
    content = <EditPortfolioContent
        profileEdited={profileEdited}
        updateEditedProfileField={updateEditedProfileField}
    />;
  } else if (sectionNameToEdit === "contact") {
    content = <EditContactContent
        profileEdited={profileEdited}
        updateEditedProfileField={updateEditedProfileField}
    />;
  }

  const onSaveProfileClicked = async () => {
    setLoading(true);

    try {
        const response = await saveProfileChanges(profileEdited, currentUser);
        if (response.status === 200) {
            setProfile(profileEdited);
        }
    }
    catch (error) {
        console.error("Error saving profile changes", error);
        // todo: visually represent error to user
    }
    setLoading(false);
    setOpen(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10 text-gray-800">
                    <FaPen size={16} aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-2 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="text-sm text-gray-500">
                        {content}
                      </div>
                    </div>
                  </div>
                </div>
                {loading && (
                    <div className="flex w-full justify-center self-start -mx-4">
                        <Loader height={80} width={80} />
                    </div>
                )}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800 sm:ml-3 sm:w-auto"
                    onClick={onSaveProfileClicked}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
