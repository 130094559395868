import React, { useState, useEffect } from 'react';
import WriteFeedback from './WriteFeedback';
import ViewFeedbackPosts from './ViewFeedbackPosts';
import axios from 'axios';
import Alert from '../Alert';
import Loader from '../Loader';

const FeedbackSection = (user) => {
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState("");
    const [loading, setLoading] = useState(false);
    const [feedbackList, setFeedbackList] = useState([]);

    const BASE_URL = process.env.REACT_APP_PUBLIC_BACKEND_URL;

    useEffect(() => {
        getFeedback();
    }, [])
    
    const getFeedback = async () => {
        try {
            setLoading(true);
            const res = await axios.post(BASE_URL + "get_feedback");
            setFeedbackList(res.data);
        } catch (e) {
            setAlertType("error");
            setAlert("An error occurred fetching feedback. Please try again.");
        }
        setLoading(false);
    }

    const createFeedback = async (description) => {
        console.log("creating feedback! desc:", description);

        if (!user || !user.user || !user.user.email) {
          setAlertType("error");
          setAlert("An error occurred submitting your feedback. Please try again.");
          return;
        } else if (!description || description.length === 0) {
          setAlertType("error");
          setAlert("Please write some feedback before submitting.");
          return;
        }
        console.log("got past error checks");
        setLoading(true);
    
        try {
            const res = await axios.post(BASE_URL + "feedback", {
                email: user.user.email,
                content: description,
            });

            if (res.status !== 200) {
                throw new Error("Failed to submit feedback");
            }
    
            setAlertType("success");
            setAlert("Feedback submitted successfully!");
            getFeedback();
        } catch (e) {
            setAlertType("error");
            setAlert("An error occurred submitting your feedback. Please try again.");
            console.log(e);
        }

        setLoading(false);
      }

    return (
        <div>
            <WriteFeedback createFeedback={createFeedback}/>
            {alert && (
                <div className="my-4 w-full sm:flex sm:justify-center">
                    <Alert type={alertType} messageBody="" messageHeader={alert} changeMessage={setAlert}/>
                </div>
            )}
            {loading ? (
                <div className="flex justify-center items-center h-full my-8">
                    <Loader />
                </div>
            ) : (
                <ViewFeedbackPosts feedbackList={feedbackList}/>
            )}
        </div>
    );
};

export default FeedbackSection;