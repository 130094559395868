import React from "react";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { motion } from "framer-motion";

const Shop = () => {
  const [activeSection, setActiveSection] = useState("hello");
  useEffect(() => {
    scroll.scrollToTop({
      duration: 1000, // Duration of the scroll animation in milliseconds
      delay: 0, // Delay before the scroll animation starts in milliseconds
      smooth: "easeInOutQuart",
    });
  }, [activeSection]);

  const renderSection = () => {
    switch (activeSection) {
      case "hello":
        return (
          <Hello
            setActiveSection={setActiveSection}
            activeSection={activeSection}
          />
        );
      case "nice":
        return (
          <Nice
            setActiveSection={setActiveSection}
            activeSection={activeSection}
          />
        );
      case "stranger":
        return (
          <Stranger
            setActiveSection={setActiveSection}
            activeSection={activeSection}
          />
        );
      case "in-touch":
        return (
          <InTouch
            setActiveSection={setActiveSection}
            activeSection={activeSection}
          />
        );
      case "excited":
        return (
          <Excited
            setActiveSection={setActiveSection}
            activeSection={activeSection}
          />
        );
      default:
        return (
          <Hello
            setActiveSection={setActiveSection}
            activeSection={activeSection}
          />
        );
    }
  };
  return (
    <div className="flex flex-col min-h-screen gap-14  w-full">
      {renderSection()}
    </div>
  );
};

export default Shop;

const Section = ({
  color,
  cards,
  setActiveSection,
  activeSection,
  children,
}) => {
  const menus = [
    {
      id: "A",
      link: "hello",
    },
    {
      id: "B",
      link: "nice",
    },
    {
      id: "C",
      link: "stranger",
    },
    {
      id: "D",
      link: "in-touch",
    },
    {
      id: "E",
      link: "excited",
    },
  ];

  //Animation constants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5, // Delays the animation of each child by 0.2 seconds
        // Delays the animation of each child by 0.2 seconds
      },
    },
  };

  // Define the children's animation variants
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    duration: 0.31,
  };
  return (
    <div className="flex flex-col justify-between w-full  items-center">
      <div className="sticky top-2 backdrop-filter backdrop-blur-lg px-4 py-2 rounded-xl z-50">
        <motion.div className="flex flex-row gap-2 text-sm text-white font-light ">
          {menus.map((menu, index) => (
            <motion.div
              onClick={() => setActiveSection(menu.link)}
              activeClassName="bg-opacity-100"
              key={index}
              variants={itemVariants}
              transition={{ duration: 0.1, ease: "anticipate" }}
              className={`flex bg-black justify-center items-center align-middle text-center rounded-full h-8 w-8 ${
                activeSection === menu.link ? "bg-opacity-100" : "bg-opacity-50"
              } hover:bg-opacity-80 hover:cursor-pointer`}
            >
              {menu.id}
            </motion.div>
          ))}
        </motion.div>
      </div>
      <div
        className={`flex flex-col justify-center  py-32 px-8 w-full items-center ${color} text-6xl text-white`}
      >
        <p>{children}</p>
      </div>
      <motion.ul
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        className="flex w-full flex-wrap p-4 gap-2 bg-white justify-center items-start"
      >
        {cards &&
          cards.map((card, index) => (
            <motion.li
              activeClassName="bg-opacity-100"
              key={index}
              initial="hidden"
              whileInView="visible"
              variants={itemVariants}
              transition={{ duration: 1, ease: "anticipate" }}
              viewport={{ once: true, amount: 0.2 }}
            >
              <Card key={index} card={card} />
            </motion.li>
          ))}
      </motion.ul>
    </div>
  );
};

const Card = ({ card }) => {
  const buyIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-5 h-5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
      />
    </svg>
  );
  return (
    <div className="flex flex-col py-10  lg:px-20 px-4 bg-neutral-50 justify-center group group-hover hover:bg-neutral-100 rounded-lg">
      <img
        src={card.image}
        alt="hello card"
        className="rounded-lg -rotate-2 w-auto h-48 object-cover shadow-xl group-hover:scale-105 transition-all duration-200 "
      />
      <div className="flex justify-between p-4">
        <p className="text-lg  mt-4">{card.color}</p>
        <p className="text-lg font-bold mt-4 ">${card.price}</p>
      </div>
      <a href={card.link} target="_blank" rel="noreferrer">
        <div className="flex mt-4 py-2 rounded-lg  justify-center items-center text-lg text-gray-100 hover:text-gray-200 w-full hover:cursor-pointer bg-neutral-800 hover:bg-neutral-700">
          <div className="group-hover:-rotate-6">{buyIcon}</div>

          <p className="flex  text-center justify-center rounded-lg px-2 ">
            Buy
          </p>
        </div>
      </a>
    </div>
  );
};

const Hello = ({ setActiveSection, activeSection }) => {
  const helloCards = [
    {
      id: 1,
      color: "Coral Pink",
      link: "https://buy.stripe.com/3cs4kdcWeczx7N66oq",
      image: require("../Assets/img/shop/card-01.png"),
      price: 19.99,
    },
    {
      id: 2,
      color: "Moonlight White",
      link: "https://buy.stripe.com/4gw5ohg8q2YX6J2eUX",
      image: require("../Assets/img/shop/card-02.png"),
      price: 19.99,
    },
    {
      id: 3,
      color: "Emerald Green",
      link: "https://buy.stripe.com/eVadUN3lEfLJaZi148",
      image: require("../Assets/img/shop/card-03.png"),
      price: 19.99,
    },
    {
      id: 4,
      color: "Midnight Black",
      link: "https://buy.stripe.com/4gw03XcWe2YXffyaEJ",
      image: require("../Assets/img/shop/card-04.png"),
      price: 19.99,
    },
  ];
  return (
    <Section
      setActiveSection={setActiveSection}
      activeSection={activeSection}
      color={"bg-green-600"}
      cards={helloCards}
    >
      You got me at <span className="font-bold">hello.</span>
    </Section>
  );
};

const Nice = ({ setActiveSection, activeSection }) => {
  const niceCards = [
    {
      id: 1,
      color: "Coral Pink",
      link: "https://buy.stripe.com/14keYR2hA2YXgjC5kq",
      image: require("../Assets/img/shop/card-05.png"),
      price: 19.99,
    },
    {
      id: 2,
      color: "Moonlight White",
      link: "https://buy.stripe.com/3cs1814pIfLJ3wQ6ov",
      image: require("../Assets/img/shop/card-06.png"),
      price: 19.99,
    },
    {
      id: 3,
      color: "Emerald Green",
      link: "https://buy.stripe.com/cN2dUN9K24315EYdQY",
      image: require("../Assets/img/shop/card-07.png"),
      price: 19.99,
    },
    {
      id: 4,
      color: "Midnight Black",
      link: "https://buy.stripe.com/7sIcQJ9K21UT3wQ4gp",
      image: require("../Assets/img/shop/card-08.png"),
      price: 19.99,
    },
  ];
  return (
    <Section
      color={"bg-pink-500"}
      cards={niceCards}
      setActiveSection={setActiveSection}
      activeSection={activeSection}
    >
      Nice to <span className="font-bold">meet</span> you.
    </Section>
  );
};

const Stranger = ({ setActiveSection, activeSection }) => {
  const strangerCards = [
    {
      id: 1,
      color: "Coral Pink",
      link: "https://buy.stripe.com/6oE5ohg8qeHF2sMfZ8",
      image: require("../Assets/img/shop/card-09.png"),
      price: 19.99,
    },
    {
      id: 2,
      color: "Moonlight White",
      link: "https://buy.stripe.com/28o03XaO6eHF9Ve28j",
      image: require("../Assets/img/shop/card-10.png"),
      price: 19.99,
    },
    {
      id: 3,
      color: "Emerald Green",
      link: "https://buy.stripe.com/dR6eYRg8q2YX2sMfZa",
      image: require("../Assets/img/shop/card-11.png"),
      price: 19.99,
    },
    {
      id: 4,
      color: "Midnight Black",
      link: "https://buy.stripe.com/00gaIB6xQ8jhffy7sF",
      image: require("../Assets/img/shop/card-12.png"),
      price: 19.99,
    },
  ];
  return (
    <Section
      color={"bg-black"}
      cards={strangerCards}
      setActiveSection={setActiveSection}
      activeSection={activeSection}
    >
      Don't be a <span className="font-bold">stranger</span>.
    </Section>
  );
};

const InTouch = ({ setActiveSection, activeSection }) => {
  const inTouchCards = [
    {
      id: 1,
      color: "Coral Pink",
      link: "https://buy.stripe.com/6oE9Ex8FY431gjC00e",
      image: require("../Assets/img/shop/card-13.png"),
      price: 19.99,
    },
    {
      id: 2,
      color: "Moonlight White",
      link: "https://buy.stripe.com/9AQ1818FYarp2sM28n",
      image: require("../Assets/img/shop/card-14.png"),
      price: 19.99,
    },
    {
      id: 3,
      color: "Emerald Green",
      link: "https://buy.stripe.com/00g4kd3lEdDB0kEaEU",
      image: require("../Assets/img/shop/card-15.png"),
      price: 19.99,
    },
    {
      id: 4,
      color: "Midnight Black",
      link: "https://buy.stripe.com/fZe6sl3lEczx4AU9AR",
      image: require("../Assets/img/shop/card-16.png"),
      price: 19.99,
    },
  ];
  return (
    <Section
      color={"bg-red-600"}
      cards={inTouchCards}
      setActiveSection={setActiveSection}
      activeSection={activeSection}
    >
      Stay <span className="font-bold">in touch</span>.
    </Section>
  );
};

const Excited = ({ setActiveSection, activeSection }) => {
  const excitedCards = [
    {
      id: 1,
      color: "Coral Pink",
      link: "https://buy.stripe.com/4gw2c53lE2YXffyeVc",
      image: require("../Assets/img/shop/card-17.png"),
      price: 19.99,
    },
    {
      id: 2,
      color: "Moonlight White",
      link: "https://buy.stripe.com/28og2V8FY1UTgjC4gz",
      image: require("../Assets/img/shop/card-18.png"),
      price: 19.99,
    },
    {
      id: 3,
      color: "Emerald Green",
      link: "https://buy.stripe.com/28o1813lE0QP9Ve28s",
      image: require("../Assets/img/shop/card-19.png"),
      price: 19.99,
    },
    {
      id: 4,
      color: "Midnight Black",
      link: "https://buy.stripe.com/7sI181cWe9nl8Ra14p",
      image: require("../Assets/img/shop/card-20.png"),
      price: 19.99,
    },
  ];
  return (
    <Section
      color={"bg-yellow-500"}
      cards={excitedCards}
      setActiveSection={setActiveSection}
      activeSection={activeSection}
    >
      Excited for <span className="font-bold">what's</span> next.
    </Section>
  );
};
