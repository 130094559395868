import React from "react";
import logo from "../Assets/logo-blue.svg";

const Logo = () => {
  return (
    <div className="h-8 w-8">
      <img src={logo} />
    </div>
  );
};

export default Logo;
