import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCVLaXW-QjoO8jjFFUd6UOlaTw31YrvtmM",
  authDomain: "penpal-mobile-887f9.firebaseapp.com",
  projectId: "penpal-mobile-887f9",
  storageBucket: "penpal-mobile-887f9.appspot.com",
  messagingSenderId: "354920867625",
  appId: "1:354920867625:web:f67b28edfb5cb23f16b938",
  measurementId: "G-6EY074S2H7"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)



export { app, auth };
