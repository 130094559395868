import { handleFileUpload } from "../../scripts/S3";

const Question = ({ question, value, onChange, answers }) => {
  if (!question) {
    return <div>Loading question...</div>; // Or handle the undefined state appropriately
  }

  function addSpacesToPhoneNumber(phoneNumber) {
    // remove all spaces from the string
    phoneNumber = phoneNumber.replace(/\s/g, "");

    let newPhoneNum = "";

    for (let i = 0; i < Math.min(phoneNumber.length, 10); i++) {
      if (i === 3 || i === 6) {
        newPhoneNum += " ";
      }
      newPhoneNum += phoneNumber[i];
    }

    return newPhoneNum;
  }

  const handleInputChange = (e) => {
    const { value: optionValue, checked } = e.target;

    if (question.type === "open") {
      onChange(question.id, optionValue);
    } else if (question.type === "tel") {
      // add spaces to phone num so it's formatted nicer
      const phoneNumWithSpaces = addSpacesToPhoneNumber(optionValue);
      onChange(question.id, phoneNumWithSpaces);
    } else if (question.type === "close") {
      // Assuming value is always an array for close-ended questions
      onChange(question.id, optionValue);
    } else if (question.type === "file") {
      onChange(question.id, e.target.files[0]);
    }
  };

  const bulbIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
      />
    </svg>
  );

  return (
    <div
      className="animate-slide-in-bottom-fast flex flex-col gap-4 md:ml-4 sm:mt-8"
      key={question.id}
    >
      <div className="">
        {question.type === "info" ? (
          <div className="flex align-middle items-center  gap-2 mb-2 text-xl font-light text-blue-800 py-2 animate-slide-in-bottom-fast border-b-2 px-2 border-blue-600 self-start md:w-2/3">
            <div className="text-blue-700">{bulbIcon}</div>
            <div className="">{question.text}</div>
          </div>
        ) : (
          <h2 className="mb-4 text-2xl text-gray-800 animate-slide-in-bottom-fast">
            {question.text}
          </h2>
        )}
        {question.description && (
          <p className="mb-2 text-md font-l leading-loose text-neutral-500 lg:w-2/3 ">
            {question.description}
          </p>
        )}
        {question.type === "open" && (
          <div className="flex flex-col">
            <input
              type="text"
              placeholder={question.placeholder || "Type your answer here"}
              value={value}
              onChange={handleInputChange}
              maxLength={question.maxLen || 1000}
              className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight border-black w-full outline-none outline-transparent text-lg text-black"
            />
            {question.maxLen && (
              <div className="text-right text-sm text-neutral-500">
                {value.length}/{question.maxLen}
              </div>
            )}
          </div>
        )}
        {question.type === "tel" && (
          <input
            type="text"
            placeholder={question.placeholder || "123-456-7890"}
            value={value}
            onChange={handleInputChange}
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-4 border-black w-full outline-none outline-transparent text-lg text-black"
          />
        )}
        {(question.type === "close" && question.options) &&
          <select
              value={question.options.find((option) => option.text === value)?.text || question.options[0].text}
              onChange={handleInputChange}
              className="border p-2 rounded-md placeholder:text-neutral-400 font-extralight mt-2 border-black w-full outline-none outline-transparent text-md text-black"
          >
            {question.options.map((option, index) => (
                  <option key={index} value={option.text}>{option.text}</option>
            ))}
          </select>
        }
        {question.type === "file" && (
          <div className="">
            {answers[question.id] && (
              <img
                className="h-48 w-48 object-top object-cover rounded-3xl shadow-lg md:h-56 md:w-56 mb-8"
                src={answers[question.id]}
                alt="Profile pic"
              />
            )}
            <input
              type="file"
              onChange={async (e) => {
                const file = e.target.files[0];
                if (file) {
                  // Call function to handle the file upload process
                  const profilePicUrl = await handleFileUpload(file);
                  onChange(question.id, profilePicUrl);
                  // todo: handle error
                }
              }}
              className="border p-2 rounded-md"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Question;
