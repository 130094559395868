import NewlineText from '../NewlineText'

export default function Post ({ content, id }) {
    return (
    <li key={id} className="flex gap-x-4 py-5">
        <div className="flex-auto">
            <div className="flex items-center justify-between gap-x-4">
                <div className="flex items-center gap-x-2">
                    <p className="flex text-sm leading-6 text-gray-800 font-semibold">
                        Posted anonymously
                    </p>
                </div>
                {/* <p className="flex-none text-xs text-gray-200">
                    <time dateTime={post.dateTime}>{post.date}</time>
                </p> */}
            </div>
            <div className="flex items-center gap-x-2 text-gray-800">
                <NewlineText text={content} truncate={false} />
            </div>
        </div>
    </li>
  );
}